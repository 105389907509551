<template>
    <div class="images clearfix" v-viewer>
        <template v-for="image in images">
            <img :src="image" class="image" :key="image">
        </template>
    </div>
       
</template>
<script>
    export default {
        name: "ProductImg",
        data: function () {
            return {
                images:[]
            }
        },
        props: {
            config: {
                ImgList: [],
            }
        },
        methods: {
            init: function () {
                var _this = this;
                if (_this.config.ImgList != null && _this.config.ImgList != undefined && _this.config.ImgList.length > 0) {
                    _this.images = _this.config.ImgList;
                } 
            },
            newImg: function (list) {
                this.images = list;
            }
        },
        watch: {
            config: function (newData,oldData) {
                if (newData.ImgList != oldData.ImgList) {

                    this.images = newData.ImgList;
                }
            }
        },
        mounted() {
            console.log("初始化数据:" + JSON.stringify(this.config.ImgList));
            var _this = this;
            _this.init();
        },
    }
</script>
<style>
    .image {
        height: 200px;
        cursor: pointer;
        margin: 5px;
        display: inline-block;
    }
    .noImg {
      width:100%;
      height:300px;
      text-align:center;
      line-height:100%;
      color:gray;
    }
</style>